import EventService from "Services/EventEmitter";
import DevModule from "Configs/Module/development.json";
import StagingModule from "Configs/Module/staging.json";
import PreprodTestnetModule from "Configs/Module/preprod-testnet.json";
import PreprodMainnetModule from "Configs/Module/preprod-mainnet.json";
import ProductionModule from "Configs/Module/production.json";

const Modules = {
	development: DevModule,
	staging: StagingModule,
	"preprod-testnet": PreprodTestnetModule,
	"preprod-mainnet": PreprodMainnetModule,
	production: ProductionModule,
};

export default class ModuleStore {
	private static instance: ModuleStore;
	private readonly event = new EventService();
	private _module = Modules.development;

	constructor() {
		ModuleStore.instance = this;
	}

	public static getInstance() {
		if (!ModuleStore.instance) return new this();
		return ModuleStore.instance;
	}

	public get module() {
		return this._module;
	}

	public set module(module: ModuleStore["_module"]) {
		this._module = module;
	}

	public async load(): Promise<void> {
		try {
			this.module = Modules[(process.env["REACT_APP_ENV_NAME"] as keyof typeof Modules) || "development"];
			this.event.emit("change", this.module);
		} catch (e) {
			console.error(e);
		}
	}

	public onChange(callback: (module: ModuleStore["_module"]) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}
}
