import { CreditCardIcon } from "@heroicons/react/24/outline";
import assert from "assert";
import { ethers } from "ethers";
import React, { ReactElement } from "react";
import { ETokenSymbol } from "common/enums/currency";
import TokenHelper from "common/helpers/token";
import TransactionHelper from "common/helpers/transaction";
import TransactionToast from "common/helpers/TransactionToast";
import SelectorBar, { EChooseButtonSelected } from "Components/Elements/Buttons/ChooseButton";
import I18n from "Components/Elements/I18n";
import MockedTokenIcon from "Components/Elements/Icons/MockedTokenIcon";
import PaymentModal, { EApprovedPaymentButtonState } from "Components/Elements/Modals/PaymentModal";
import GasPriceAlert from "Components/Elements/Modals/PaymentModal/GasPriceAlert";
import Typography from "Components/Elements/Typography";
import BigNumber from "Services/BigNumber";
import { EModal } from "Stores/Modals/AModalStore";
import CompleteMintModalStore, { ICompleteMintModalProps } from "Stores/Modals/CompleteMintModalStore";
import WalletBalanceStore, { IBalances } from "Stores/WalletBalanceStore";
import WalletStore from "Stores/WalletStore";
import IsModuleEnabled from "../../../Elements/IsModuleEnabled";
import BalanceComponent from "./BalanceComponent";
import classes from "./classes.module.scss";
import AppWert, { MintWertParams } from "Api/Back/AppWert";
import { ECollectionPhaseName } from "Entities/appCollectionPhase";
import AppWhitelist from "Api/Back/AppWhitelist";
import MintModalHeader from "./MintModalHeader";
import Checkbox from "Components/Elements/Checkbox";
import Documents from "Components/Materials/Documents";
import { TokenProtocol } from "common/enums/TokenProcotol";
import Erc721ContractStore from "Stores/ContractStores/Erc721ContractStore";
import { useModal } from "hooks/useModal";
import AddFunds from "../AddFundsModal";
import { EWidgetType } from "common/enums/Wert";
import WertModal from "../WertModal";

type IProps = {
	showModal: (content: ReactElement) => void;
	hideModal: () => void;
};

type IState = {
	isMintTxExecuted: boolean;
	isMintButtonClicked: boolean;
	paymentMethod: EPaymentMethod;
	paymentInfo?: ICompleteMintModalProps;
	isOpen: boolean;
	isApproveButtonDisabled: boolean;
	approvedPaymentButtonState: EApprovedPaymentButtonState;
	isCreditCardComponentDisplayed: boolean;
	isAddFundsDisplayed: boolean;
	isConditionPoliciesChecked: boolean;
	balances: IBalances;
	gasPrice: BigNumber;
	tx?: ethers.PopulatedTransaction;
};

enum EPaymentMethod {
	CRYPTO = "CRYPTO",
	CREDIT_CARD = "CREDIT_CARD",
}

// In testnet, the minimum value to mint with credit card is 1.1 dollars
// In mainnet, the minimum value to mint with credit card is 5 dollars
const env = process.env["REACT_APP_ENV_NAME"];
const MINIMUM_WERT_MINT_VALUE = env === "production" || env === "preprod-mainnet" ? 5 : 1.1;

class CompleteMintModalClass extends React.Component<IProps, IState> {
	private removeOnModalChange = () => {};
	private removeOnWalletChange = () => {};

	public constructor(props: IProps) {
		super(props);
		this.state = {
			isMintButtonClicked: false,
			isMintTxExecuted: false,
			paymentMethod: EPaymentMethod.CRYPTO,
			isOpen: CompleteMintModalStore.getInstance().isOpen(EModal.COMPLETE_MINT),
			paymentInfo: CompleteMintModalStore.getInstance().getProps(),
			approvedPaymentButtonState: EApprovedPaymentButtonState.CLICKABLE,
			isApproveButtonDisabled: false,
			isCreditCardComponentDisplayed: true,
			balances: WalletBalanceStore.getInstance().balances,
			isAddFundsDisplayed: false,
			gasPrice: BigNumber.from("0"),
			isConditionPoliciesChecked: false,
		};
		this.completeMint = this.completeMint.bind(this);
		this.updatePaymentMethod = this.updatePaymentMethod.bind(this);
		this.updateModalState = this.updateModalState.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.updateDisplayAddFunds = this.updateDisplayAddFunds.bind(this);
		this.openAddFundsModal = this.openAddFundsModal.bind(this);
		this.updateModalState = this.updateModalState.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.estimateGasPrice = this.estimateGasPrice.bind(this);
		this.approvePayment = this.approvePayment.bind(this);
		this.checkHasEnoughAllowance = this.checkHasEnoughAllowance.bind(this);
		this.mintNftByCard = this.mintNftByCard.bind(this);
		this.onCheckboxChange = this.onCheckboxChange.bind(this);
	}

	override render(): JSX.Element | null {
		// /!\ If this component is not rendered check if nft, amountToPay and token are set when you open this modal
		const header: JSX.Element = <span>{I18n.translate("modals.complete_mint.title")}</span>;
		if (!this.state.paymentInfo) return null;
		const { appTokenSupport } = this.state.paymentInfo;
		return (
			<PaymentModal
				isOpen={this.state.isOpen}
				isConfirmTxExecuted={this.state.isMintTxExecuted}
				isConfirmButtonClicked={this.state.isMintButtonClicked}
				header={header}
				closeBtn
				onClose={this.closeModal}
				confirmText={<I18n map="modals.complete_mint.button" />}
				onConfirm={this.completeMint}
				approvePayment={this.approvePayment}
				isAddFundsDisplayed={this.state.isAddFundsDisplayed}
				openAddFundsModal={this.openAddFundsModal}
				errorMessageAddFunds={this.getAddFundsMessage()}
				appTokenSupport={appTokenSupport}
				isConfirmButtonDisabled={!this.state.isConditionPoliciesChecked}
				stateApprovePaymentButton={this.getApprovePaymentButtonState()}>
				<div className={classes["root"]}>
					<MintModalHeader
						amountToMint={this.state.paymentInfo.amountToMint}
						appCollection={this.state.paymentInfo.appCollection}
						price={{ value: this.getAmountToPay(), token: this.state.paymentInfo.appTokenSupport }}
					/>
					{this.state.isCreditCardComponentDisplayed && (
						<IsModuleEnabled from={IsModuleEnabled.get().PaymentCreditCard}>
							<div className={classes["choose-payment-method"]}>
								<Typography type="p" size="small" weight="medium">
									<I18n map="common.choose_a_payment_method" />
								</Typography>
								<SelectorBar
									startIconLeft={<MockedTokenIcon symbol={ETokenSymbol.MATIC} />}
									startIconRight={<CreditCardIcon />}
									selected={
										this.state.paymentMethod === EPaymentMethod.CRYPTO
											? EChooseButtonSelected.LEFT
											: EChooseButtonSelected.RIGHT
									}
									onChange={this.updatePaymentMethod}
									unDisplayRightButton={!IsModuleEnabled.get().PaymentCreditCard.enabled}
								/>
							</div>
						</IsModuleEnabled>
					)}
					{this.state.paymentMethod === EPaymentMethod.CRYPTO && !this.state.isAddFundsDisplayed && (
						<GasPriceAlert gasPrice={this.state.gasPrice} />
					)}
					{this.state.paymentMethod === EPaymentMethod.CRYPTO && (
						<BalanceComponent
							token={appTokenSupport}
							price={this.getAmountToPay()}
							updateDisplayAddFunds={this.updateDisplayAddFunds}
						/>
					)}
					<div className={classes["condition-policies"]}>
						<Checkbox onChange={this.onCheckboxChange} />
						<div className={classes["text-condition"]}>
							<Typography type="p" size="small" weight="regular" color="neutral">
								<I18n map="modals.complete_mint.agree_info" />
							</Typography>
							<a href={Documents.nftSaleTermsAndConditions} target="_blank" rel="noreferrer">
								<Typography color="neutral" type="p" size="small" weight="medium">
									<I18n map="modals.complete_mint.terms_and_conditions" />
								</Typography>
							</a>
						</div>
					</div>
				</div>
			</PaymentModal>
		);
	}

	public override componentDidMount() {
		this.removeOnWalletChange = WalletStore.getInstance().onChange(this.checkHasEnoughAllowance);
		this.removeOnModalChange = CompleteMintModalStore.getInstance().onChange(this.updateModalState);
		this.updateModalState(CompleteMintModalStore.getInstance().modalsOpenedState, CompleteMintModalStore.getInstance().getProps());
	}

	public override async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
		if (prevState.isOpen !== this.state.isOpen) {
			await this.checkHasEnoughAllowance();
			await this.updateDisplayCreditCardComponent();
			this.setState({
				isMintTxExecuted: false,
				isMintButtonClicked: false,
				paymentMethod: EPaymentMethod.CRYPTO,
				isConditionPoliciesChecked: false,
			});
		}
	}

	public override componentWillUnmount() {
		this.removeOnWalletChange();
		this.removeOnModalChange();
	}

	private getApprovePaymentButtonState(): EApprovedPaymentButtonState {
		return this.state.paymentMethod === EPaymentMethod.CRYPTO
			? this.state.approvedPaymentButtonState
			: EApprovedPaymentButtonState.UNDISPLAY;
	}

	private updateModalState(modalsOpenedState: { [type: string]: boolean }, paymentInfo?: ICompleteMintModalProps) {
		this.setState({ isOpen: modalsOpenedState[EModal.COMPLETE_MINT] ?? false, paymentInfo });
	}

	private closeModal() {
		CompleteMintModalStore.getInstance().closeModal();
	}

	private getAmountToPay(): BigNumber {
		if (!this.state.paymentInfo) return BigNumber.from("0");
		const { price, amountToMint } = this.state.paymentInfo;
		return price.mul(amountToMint);
	}

	private async getAutoWhitelistMintSignature(): Promise<ethers.Signature> {
		assert(this.state.paymentInfo, "Payment info is undefined in complete mint modal");
		const { phaseId } = this.state.paymentInfo;
		const signature = await AppWhitelist.getInstance().autoWhitelistAndSignMessage(phaseId);
		return signature.splitSignature;
	}

	private async getMintSplitSignature(): Promise<ethers.Signature> {
		assert(this.state.paymentInfo, "Payment info is undefined in complete mint modal");
		const { phaseId } = this.state.paymentInfo;
		const signature = await AppWhitelist.getInstance().signMessage(phaseId);
		return signature.splitSignature;
	}

	private updatePaymentMethod(selected: "left" | "right") {
		if (selected === "right") this.setState({ isAddFundsDisplayed: false });
		this.setState(
			{ paymentMethod: selected === "left" ? EPaymentMethod.CRYPTO : EPaymentMethod.CREDIT_CARD },
			this.checkHasEnoughAllowance,
		);
	}

	private toggleApproveButtonDisabled(isDisabled: boolean) {
		this.setState({ isApproveButtonDisabled: isDisabled });
	}

	private async updateDisplayCreditCardComponent() {
		if (!this.state.paymentInfo) return;
		const { price, appTokenSupport } = this.state.paymentInfo;
		const amountToPayInUSD = Number(price.mul(appTokenSupport.USDRatio).shiftLeft(appTokenSupport.decimals).getFormatRoundFloor(2));
		if (!amountToPayInUSD) return;
		const isTokenSupportedByWert = await AppWert.getInstance().isTokenSupported(appTokenSupport.symbol);
		this.setState({ isCreditCardComponentDisplayed: isTokenSupportedByWert && amountToPayInUSD >= MINIMUM_WERT_MINT_VALUE });
	}

	private updateDisplayAddFunds(availableAmount: BigNumber) {
		if (this.state.paymentMethod === EPaymentMethod.CREDIT_CARD) this.setState({ isAddFundsDisplayed: false });
		const buyNowPrice = this.getAmountToPay();
		if (!buyNowPrice) return;
		const displayingAddFunds = availableAmount.lt(buyNowPrice);
		this.setState({ isAddFundsDisplayed: displayingAddFunds });
		this.toggleApproveButtonDisabled(displayingAddFunds);
		this.checkHasEnoughAllowance();
	}

	private updateApprovedButtonState(state: EApprovedPaymentButtonState) {
		const token = this.state.paymentInfo?.appTokenSupport;
		if (!token) return;
		let stateApprovePaymentButton = state;
		if (this.state.isApproveButtonDisabled) stateApprovePaymentButton = EApprovedPaymentButtonState.DISABLED;
		if (TokenHelper.isTokenNative(this.state.paymentInfo?.appTokenSupport))
			stateApprovePaymentButton = EApprovedPaymentButtonState.UNDISPLAY;
		this.setState({ approvedPaymentButtonState: stateApprovePaymentButton }, this.estimateGasPrice);
	}

	private async checkHasEnoughAllowance() {
		if (TokenHelper.isTokenNative(this.state.paymentInfo?.appTokenSupport)) {
			this.updateApprovedButtonState(EApprovedPaymentButtonState.UNDISPLAY);
			return;
		}
		if (!this.state.paymentInfo) return;
		const { appCollection, appTokenSupport } = this.state.paymentInfo;

		try {
			const collectionAddress = appCollection.appContract?.address;
			if (!collectionAddress) throw new Error("No collection address");
			const allowance = await TransactionHelper.checkCurrentAllowance(appTokenSupport, collectionAddress);
			if (!allowance) return;
			this.updateApprovedButtonState(
				allowance.lt(this.getAmountToPay()) ? EApprovedPaymentButtonState.CLICKABLE : EApprovedPaymentButtonState.APPROVED,
			);
		} catch (e) {
			console.error(e);
		}
	}

	private async approvePayment() {
		this.updateApprovedButtonState(EApprovedPaymentButtonState.LOADING);
		await TransactionToast.processTransaction(() => WalletStore.getInstance().sendTransaction(this.state.tx));
		this.checkHasEnoughAllowance();
	}

	private async estimateGasPrice() {
		let tx: ethers.PopulatedTransaction | undefined;
		if (!this.state.paymentInfo) return;

		const { appCollection, amountToMint, appTokenSupport, phaseName } = this.state.paymentInfo;
		const value = this.getAmountToPay().toString(10);
		const isMintInNativeToken = appTokenSupport.protocol === TokenProtocol.NATIVE;
		const erc721ContractAddress = appCollection.appContract?.address;
		if (!erc721ContractAddress) throw new Error("No collection address");

		try {
			if (isMintInNativeToken || this.state.approvedPaymentButtonState === EApprovedPaymentButtonState.APPROVED) {
				const enrichedErc721Contract = await Erc721ContractStore.getInstance().getEnrichedContractByAddress(erc721ContractAddress);

				const recipientAddress = WalletStore.getInstance().walletData.userAddress;
				assert(recipientAddress, "Recipient address is undefined");

				switch (phaseName) {
					case ECollectionPhaseName.PUBLIC_SALE:
						if (!enrichedErc721Contract.contractAdapter.canUse("createMintPublicSaleTx")) break;

						tx = await enrichedErc721Contract.contractAdapter.createMintPublicSaleTx(amountToMint, recipientAddress, value);
						break;
					case ECollectionPhaseName.PRIVATE_SALE:
						if (!enrichedErc721Contract.contractAdapter.canUse("createMintPrivateSaleTx")) break;
						const splitSignature = await this.getMintSplitSignature();

						tx = await enrichedErc721Contract.contractAdapter.createMintPrivateSaleTx(
							amountToMint,
							splitSignature,
							recipientAddress,
							value,
						);
						break;
					case ECollectionPhaseName.PRIVATE_AUTO_WHITELIST_SALE:
						if (!enrichedErc721Contract.contractAdapter.canUse("createMintPrivateSaleTx")) break;
						const autoWhitlistSplitSignature = await this.getAutoWhitelistMintSignature();

						tx = await enrichedErc721Contract.contractAdapter.createMintPrivateSaleTx(
							amountToMint,
							autoWhitlistSplitSignature,
							recipientAddress,
							value,
						);
						break;
					default:
						//TODO mint freemint
						break;
				}
			} else {
				const allowance = await TransactionHelper.checkCurrentAllowance(appTokenSupport, erc721ContractAddress);
				if (allowance && allowance.lt(this.getAmountToPay()))
					tx = await TransactionHelper.createApprovePaymentTx(erc721ContractAddress, appTokenSupport);
			}

			const enrichedErc721Contract = await Erc721ContractStore.getInstance().getEnrichedContractByAddress(erc721ContractAddress);
			if (!tx) throw new Error("tx is undefined");

			const gasPrice = enrichedErc721Contract.contractAdapter.canUse("estimateGasPrice")
				? await enrichedErc721Contract.contractAdapter.estimateGasPrice(tx)
				: null;

			if (!gasPrice) throw new Error("Gas price is undefined");

			this.setState({ gasPrice, tx });
		} catch (e) {
			console.error(e);
		}
	}

	private async mintNftByCard() {
		if (!this.state.paymentInfo) return;
		const { appCollection, amountToMint, appTokenSupport, price, phaseName, phaseId } = this.state.paymentInfo;
		const collectionAddress = appCollection.appContract?.address;
		if (!collectionAddress) throw new Error("No collection address");

		const mintParams: MintWertParams = {
			contractAddress: collectionAddress,
			tokenSymbol: appTokenSupport.symbol,
			amountToMint,
			mintPrice: price.toString(10),
		};

		let signature: string = "";
		switch (phaseName) {
			case ECollectionPhaseName.PRIVATE_SALE:
				signature = await AppWert.getInstance().signPrivateMint(mintParams, phaseId);
				break;
			case ECollectionPhaseName.PRIVATE_AUTO_WHITELIST_SALE:
				signature = await AppWert.getInstance().signPrivateAutoWhitelistMint(mintParams, phaseId);
				break;
			case ECollectionPhaseName.PUBLIC_SALE:
				signature = await AppWert.getInstance().signPublicMint(mintParams);
				break;
			default:
				throw new Error("Phase name not supported");
		}

		this.props.showModal(
			<WertModal signature={signature} widgetType={EWidgetType.MINT} appCollection={appCollection} onClose={this.props.hideModal} />,
		);
		this.closeModal();
	}

	private async completeMint() {
		if (this.state.paymentMethod === EPaymentMethod.CREDIT_CARD) return await this.mintNftByCard();
		if (!this.state.paymentInfo) return;
		const { onCompleteMint } = this.state.paymentInfo;

		this.setState({ isMintButtonClicked: true });
		try {
			await TransactionToast.processTransaction(async () => await WalletStore.getInstance().sendTransaction(this.state.tx));
			onCompleteMint?.();
			this.setState({ isMintTxExecuted: true });
		} catch (e) {
			this.setState({ isMintButtonClicked: false });
			console.error(e);
		}
	}

	private openAddFundsModal() {
		const amountToPay = this.getAmountToPay();
		const token = this.state.paymentInfo?.appTokenSupport;
		assert(amountToPay && token, "Token or AmountToPay is undefined in complete mint modal");
		this.props.showModal(<AddFunds amountToPay={amountToPay} token={token} onClose={this.props.hideModal} />);
	}

	private onCheckboxChange = (checked: boolean) => {
		this.setState({ isConditionPoliciesChecked: checked });
	};

	private getAddFundsMessage(): JSX.Element {
		return <I18n map={"modals.complete_mint.info_1"} vars={{ tokenName: this.state.paymentInfo?.appTokenSupport.name ?? "MATIC" }} />;
	}
}

export default function CompleteMintModal() {
	const { showModal, hideModal } = useModal();
	return <CompleteMintModalClass showModal={showModal} hideModal={hideModal} />;
}
