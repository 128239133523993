import classNames from "classnames";
import React, { ReactNode, useCallback } from "react";
import classes from "./classes.module.scss";
import Loader from "Components/Elements/Loader";
import { animated } from "@react-spring/web";

export enum EButtonVariant {
	CONTAINED = "contained",
	OUTLINED = "outlined",
	TEXT = "text",
}

export enum EButtonColor {
	PRIMARY = "primary",
	NEUTRAL = "neutral",
	DESTRUCTIVE = "destructive",
}

export enum EButtonSize {
	LARGE = "large",
	MEDIUM = "medium",
	SMALL = "small",
	XSMALL = "xsmall",
}

type IProps = {
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void> | void;
	variant?: EButtonVariant;
	size?: EButtonSize;
	color?: EButtonColor;
	fullwidth?: boolean;
	disabled?: boolean;
	children?: ReactNode;
	isLoading?: boolean;
	startIcon?: React.ReactNode;
	endIcon?: React.ReactNode;
	type?: "button" | "submit" | "reset";
	className?: string;
	style?: React.CSSProperties;
	id?: string;
};

export default function Button(props: IProps) {
	const getClassNames = useCallback(() => {
		return classNames([
			classes["root"],
			"root-button",
			props.variant ?? EButtonVariant.CONTAINED,
			props.size ?? EButtonSize.MEDIUM,
			props.color ?? EButtonColor.NEUTRAL,
			{
				fullwidth: props.fullwidth,
			},
			{
				"is-loading": props.isLoading,
			},
			props.className,
		]);
	}, [props.variant, props.size, props.color, props.fullwidth, props.isLoading, props.className]);

	return (
		<button id={props.id} style={props.style} onClick={props.onClick} disabled={props.disabled} type={props.type ?? "button"} className={getClassNames()}>
			{!props.isLoading && (
				<div className={classes["content"]}>
					{props.startIcon && props.startIcon}
					{props.children}
					{props.endIcon && props.endIcon}
				</div>
			)}

			{props.isLoading && (
				<div className={classes["content"]}>
					<Loader className={classes["loader"]} />
				</div>
			)}
		</button>
	);
}

export const AnimatedButton = animated(Button);
