import { CreditCardIcon } from "@heroicons/react/24/outline";
import assert from "assert";
import { ethers } from "ethers";
import React, { ReactElement } from "react";
import { ETokenSymbol } from "common/enums/currency";
import TokenHelper from "common/helpers/token";
import TransactionHelper from "common/helpers/transaction";
import TransactionToast from "common/helpers/TransactionToast";
import ChooseButton, { EChooseButtonSelected } from "Components/Elements/Buttons/ChooseButton";
import I18n from "Components/Elements/I18n";
import MockedTokenIcon from "Components/Elements/Icons/MockedTokenIcon";
import PaymentModal, { EApprovedPaymentButtonState } from "Components/Elements/Modals/PaymentModal";
import GasPriceAlert from "Components/Elements/Modals/PaymentModal/GasPriceAlert";
import Typography from "Components/Elements/Typography";
import BigNumber from "Services/BigNumber";
import { EModal } from "Stores/Modals/AModalStore";
import CompleteMintModalStore from "Stores/Modals/CompleteMintModalStore";
import WalletBalanceStore, { IBalances } from "Stores/WalletBalanceStore";
import WalletStore from "Stores/WalletStore";
import IsModuleEnabled from "../../../Elements/IsModuleEnabled";
import BalanceComponent from "./BalanceComponent";
import classes from "./classes.module.scss";
import AppWert, { InvestmentWertParams, ReinvestmentWertParams } from "Api/Back/AppWert";
import AppWhitelist from "Api/Back/AppWhitelist";
import Checkbox from "Components/Elements/Checkbox";
import Documents from "Components/Materials/Documents";
import Erc721ContractStore from "Stores/ContractStores/Erc721ContractStore";
import CompleteInvestModalStore, { ICompleteInvestModalProps } from "Stores/Modals/CompleteInvestModalStore";
import InvestModalHeader from "./InvestModalHeader";
import { useModal } from "hooks/useModal";
import AddFunds from "../AddFundsModal";
import { EWidgetType } from "common/enums/Wert";
import WertModal from "../WertModal";

type IPropsClass = {
	showModal: (modalContent: ReactElement) => void;
	hideModal: () => void;
};

type IState = {
	isMintTxExecuted: boolean;
	isMintButtonClicked: boolean;
	paymentMethod: EPaymentMethod;
	modalProps?: ICompleteInvestModalProps;
	isOpen: boolean;
	isApproveButtonDisabled: boolean;
	approvedPaymentButtonState: EApprovedPaymentButtonState;
	isCreditCardComponentDisplayed: boolean;
	isAddFundsDisplayed: boolean;
	isConditionPoliciesChecked: boolean;
	balances: IBalances;
	gasPrice: BigNumber;
	tx?: ethers.PopulatedTransaction;
};

enum EPaymentMethod {
	CRYPTO = "CRYPTO",
	CREDIT_CARD = "CREDIT_CARD",
}

// In testnet, the minimum value to mint with credit card is 1.1 dollars
// In mainnet, the minimum value to mint with credit card is 5 dollars
const env = process.env["REACT_APP_ENV_NAME"];
const MINIMUM_WERT_MINT_VALUE = env === "production" || env === "preprod-mainnet" ? 5 : 1.1;

class CompleteInvestModalClass extends React.Component<IPropsClass, IState> {
	private removeOnModalChange = () => {};
	private removeOnWalletChange = () => {};

	public constructor(props: IPropsClass) {
		super(props);
		this.state = {
			isMintButtonClicked: false,
			isMintTxExecuted: false,
			paymentMethod: EPaymentMethod.CRYPTO,
			isOpen: CompleteMintModalStore.getInstance().isOpen(EModal.COMPLETE_MINT),
			modalProps: CompleteInvestModalStore.getInstance().getProps(),
			approvedPaymentButtonState: EApprovedPaymentButtonState.CLICKABLE,
			isApproveButtonDisabled: false,
			isCreditCardComponentDisplayed: true,
			balances: WalletBalanceStore.getInstance().balances,
			isAddFundsDisplayed: false,
			gasPrice: BigNumber.from("0"),
			isConditionPoliciesChecked: false,
		};
		this.completeMint = this.completeMint.bind(this);
		this.updatePaymentMethod = this.updatePaymentMethod.bind(this);
		this.updateModalState = this.updateModalState.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.updateDisplayAddFunds = this.updateDisplayAddFunds.bind(this);
		this.openAddFundsModal = this.openAddFundsModal.bind(this);
		this.updateModalState = this.updateModalState.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.estimateGasPrice = this.estimateGasPrice.bind(this);
		this.approvePayment = this.approvePayment.bind(this);
		this.checkHasEnoughAllowance = this.checkHasEnoughAllowance.bind(this);
		this.mintNftByCard = this.mintNftByCard.bind(this);
		this.onCheckboxChange = this.onCheckboxChange.bind(this);
	}

	override render(): JSX.Element | null {
		// /!\ If this component is not rendered check if nft, amountToPay and token are set when you open this modal
		const header: JSX.Element = <span>{I18n.translate("modals.complete_mint.title")}</span>;
		if (!this.state.modalProps) return null;
		const { appTokenSupport } = this.state.modalProps;
		return (
			<PaymentModal
				isOpen={this.state.isOpen}
				isConfirmTxExecuted={this.state.isMintTxExecuted}
				isConfirmButtonClicked={this.state.isMintButtonClicked}
				header={header}
				closeBtn
				onClose={this.closeModal}
				confirmText={<I18n map="modals.complete_mint.button" />}
				onConfirm={this.completeMint}
				approvePayment={this.approvePayment}
				isAddFundsDisplayed={this.state.isAddFundsDisplayed}
				openAddFundsModal={this.openAddFundsModal}
				errorMessageAddFunds={this.getAddFundsMessage()}
				appTokenSupport={appTokenSupport}
				isConfirmButtonDisabled={!this.state.isConditionPoliciesChecked}
				stateApprovePaymentButton={this.getApprovePaymentButtonState()}>
				<div className={classes["root"]}>
					<InvestModalHeader
						amountToInvest={this.state.modalProps.amountToInvest}
						appCollection={this.state.modalProps.appCollection}
						price={{
							value: this.state.modalProps.amountToInvest,
							token: this.state.modalProps.appTokenSupport,
						}}
					/>
					{this.state.isCreditCardComponentDisplayed && (
						<IsModuleEnabled from={IsModuleEnabled.get().PaymentCreditCard}>
							<div className={classes["choose-payment-method"]}>
								<Typography type="p" size="small" weight="medium">
									<I18n map="common.choose_a_payment_method" />
								</Typography>
								<ChooseButton
									startIconLeft={<MockedTokenIcon symbol={ETokenSymbol.MATIC} />}
									startIconRight={<CreditCardIcon />}
									selected={
										this.state.paymentMethod === EPaymentMethod.CRYPTO
											? EChooseButtonSelected.LEFT
											: EChooseButtonSelected.RIGHT
									}
									onChange={this.updatePaymentMethod}
									unDisplayRightButton={!IsModuleEnabled.get().PaymentCreditCard.enabled}
								/>
							</div>
						</IsModuleEnabled>
					)}
					{this.state.paymentMethod === EPaymentMethod.CRYPTO && !this.state.isAddFundsDisplayed && (
						<GasPriceAlert gasPrice={this.state.gasPrice} />
					)}
					{this.state.paymentMethod === EPaymentMethod.CRYPTO && (
						<BalanceComponent token={appTokenSupport} updateDisplayAddFunds={this.updateDisplayAddFunds} />
					)}
					<div className={classes["condition-policies"]}>
						<Checkbox onChange={this.onCheckboxChange} />
						<div className={classes["text-condition"]}>
							<Typography type="p" size="small" weight="regular" color="neutral">
								<I18n map="modals.complete_mint.agree_info" />
							</Typography>
							<a href={Documents.nftSaleTermsAndConditions} target="_blank" rel="noreferrer">
								<Typography color="neutral" type="p" size="small" weight="medium">
									<I18n map="modals.complete_mint.terms_and_conditions" />
								</Typography>
							</a>
						</div>
					</div>
				</div>
			</PaymentModal>
		);
	}

	public override componentDidMount() {
		this.removeOnWalletChange = WalletStore.getInstance().onChange(this.checkHasEnoughAllowance);
		this.removeOnModalChange = CompleteInvestModalStore.getInstance().onChange(this.updateModalState);
		this.updateModalState(CompleteMintModalStore.getInstance().modalsOpenedState, CompleteInvestModalStore.getInstance().getProps());
	}

	public override async componentDidUpdate(prevProps: Readonly<IPropsClass>, prevState: Readonly<IState>) {
		if (prevState.isOpen !== this.state.isOpen) {
			await this.checkHasEnoughAllowance();
			await this.updateDisplayCreditCardComponent();
			this.setState({
				isMintTxExecuted: false,
				isMintButtonClicked: false,
				paymentMethod: EPaymentMethod.CRYPTO,
				isConditionPoliciesChecked: false,
			});
		}
	}

	public override componentWillUnmount() {
		this.removeOnWalletChange();
		this.removeOnModalChange();
	}

	private getApprovePaymentButtonState(): EApprovedPaymentButtonState {
		return this.state.paymentMethod === EPaymentMethod.CRYPTO
			? this.state.approvedPaymentButtonState
			: EApprovedPaymentButtonState.UNDISPLAY;
	}

	private updateModalState(modalsOpenedState: { [type: string]: boolean }, modalProps?: ICompleteInvestModalProps) {
		this.setState({ isOpen: modalsOpenedState[EModal.COMPLETE_INVEST] ?? false, modalProps });
	}

	private closeModal() {
		CompleteInvestModalStore.getInstance().closeModal();
	}

	private async getMintSplitSignature(): Promise<ethers.Signature> {
		assert(this.state.modalProps, "Payment info is undefined in complete mint modal");
		const { phaseId } = this.state.modalProps;
		const signature = await AppWhitelist.getInstance().signMessage(phaseId);
		return signature.splitSignature;
	}

	private updatePaymentMethod(selected: "left" | "right") {
		this.setState((prevState) => {
			return {
				...prevState,
				isAddFundsDisplayed: selected === "right" ? false : prevState.isAddFundsDisplayed,
				paymentMethod: selected === "right" ? EPaymentMethod.CREDIT_CARD : EPaymentMethod.CRYPTO,
			};
		}, this.checkHasEnoughAllowance);
	}

	private toggleApproveButtonDisabled(isDisabled: boolean) {
		this.setState({ isApproveButtonDisabled: isDisabled });
	}

	private async updateDisplayCreditCardComponent() {
		if (!this.state.modalProps) return;
		const { appTokenSupport, amountToInvest } = this.state.modalProps;
		const amountToPayInUSD = amountToInvest.mul(appTokenSupport.USDRatio);
		if (!amountToPayInUSD) return;
		const isTokenSupportedByWert = await AppWert.getInstance().isTokenSupported(appTokenSupport.symbol);
		this.setState({
			isCreditCardComponentDisplayed:
				isTokenSupportedByWert && amountToPayInUSD.gte(BigNumber.from(MINIMUM_WERT_MINT_VALUE.toString())),
		});
	}

	private updateDisplayAddFunds(availableAmount: BigNumber) {
		if (this.state.paymentMethod === EPaymentMethod.CREDIT_CARD) {
			this.setState((prevState) => {
				return { ...prevState, isAddFundsDisplayed: false };
			});
		}
		const buyNowPrice = this.state.modalProps?.amountToInvest;
		if (!buyNowPrice) return;

		const displayingAddFunds = availableAmount.lt(buyNowPrice);

		this.setState((prevState) => {
			return { ...prevState, isAddFundsDisplayed: displayingAddFunds };
		});
		this.toggleApproveButtonDisabled(displayingAddFunds);
		this.checkHasEnoughAllowance();
	}

	private updateApprovedButtonState(state: EApprovedPaymentButtonState) {
		const token = this.state.modalProps?.appTokenSupport;
		if (!token) return;
		let stateApprovePaymentButton = state;
		if (this.state.isApproveButtonDisabled) stateApprovePaymentButton = EApprovedPaymentButtonState.DISABLED;
		if (TokenHelper.isTokenNative(this.state.modalProps?.appTokenSupport))
			stateApprovePaymentButton = EApprovedPaymentButtonState.UNDISPLAY;
		this.setState({ approvedPaymentButtonState: stateApprovePaymentButton }, this.estimateGasPrice);
	}

	private async checkHasEnoughAllowance() {
		if (TokenHelper.isTokenNative(this.state.modalProps?.appTokenSupport)) {
			this.updateApprovedButtonState(EApprovedPaymentButtonState.UNDISPLAY);
			return;
		}
		if (!this.state.modalProps) return;
		const { appCollection, appTokenSupport, amountToInvest } = this.state.modalProps;

		try {
			const collectionAddress = appCollection.appContract?.address;
			if (!collectionAddress) throw new Error("No collection address");
			const allowance = await TransactionHelper.checkCurrentAllowance(appTokenSupport, collectionAddress);
			if (!allowance) return;
			this.updateApprovedButtonState(
				allowance.lt(amountToInvest) ? EApprovedPaymentButtonState.CLICKABLE : EApprovedPaymentButtonState.APPROVED,
			);
		} catch (e) {
			console.error(e);
		}
	}

	private async approvePayment() {
		this.updateApprovedButtonState(EApprovedPaymentButtonState.LOADING);
		await TransactionToast.processTransaction(() => WalletStore.getInstance().sendTransaction(this.state.tx));
		this.checkHasEnoughAllowance();
	}

	private async estimateGasPrice() {
		let tx: ethers.PopulatedTransaction | undefined;
		if (!this.state.modalProps) return;
		const { appCollection, appTokenSupport, amountToInvest } = this.state.modalProps;
		const erc721ContractAddress = appCollection.appContract?.address;
		if (!erc721ContractAddress) throw new Error("No collection address");

		try {
			if (this.state.approvedPaymentButtonState === EApprovedPaymentButtonState.APPROVED) {
				const enrichedErc721Contract = await Erc721ContractStore.getInstance().getEnrichedContractByAddress(erc721ContractAddress);

				const recipientAddress = WalletStore.getInstance().walletData.userAddress;
				assert(recipientAddress, "Recipient address is undefined");

				const nft = this.state.modalProps.nft;

				if (nft) {
					if (!enrichedErc721Contract.contractAdapter.canUse("createIncreaseInvestmentTx")) return;
					const splitSignature = await this.getMintSplitSignature();
					tx = await enrichedErc721Contract.contractAdapter.createIncreaseInvestmentTx(
						nft.tokenId,
						amountToInvest,
						splitSignature,
					);
				} else {
					if (!enrichedErc721Contract.contractAdapter.canUse("createInitializeInvestmentTx")) return;
					const splitSignature = await this.getMintSplitSignature();
					tx = await enrichedErc721Contract.contractAdapter.createInitializeInvestmentTx(
						recipientAddress,
						amountToInvest,
						splitSignature,
					);
				}
			} else {
				const allowance = await TransactionHelper.checkCurrentAllowance(appTokenSupport, erc721ContractAddress);
				if (allowance && allowance.lt(amountToInvest)) {
					tx = await TransactionHelper.createApprovePaymentTx(erc721ContractAddress, appTokenSupport);
				}
			}

			const enrichedErc721Contract = await Erc721ContractStore.getInstance().getEnrichedContractByAddress(erc721ContractAddress);
			if (!tx) throw new Error("tx is undefined");

			const gasPrice = enrichedErc721Contract.contractAdapter.canUse("estimateGasPrice")
				? await enrichedErc721Contract.contractAdapter.estimateGasPrice(tx)
				: null;

			if (!gasPrice) throw new Error("Gas price is undefined");

			this.setState({ gasPrice, tx });
		} catch (e) {
			console.error(e);
		}
	}

	private async mintNftByCard() {
		if (!this.state.modalProps) return;
		const { appCollection, amountToInvest, appTokenSupport, phaseId } = this.state.modalProps;
		const collectionAddress = appCollection.appContract?.address;
		if (!collectionAddress) throw new Error("No collection address");

		const investmentParams: InvestmentWertParams = {
			contractAddress: collectionAddress,
			amountToInvest: amountToInvest.toString(),
			tokenSymbol: appTokenSupport.symbol,
		};

		const signature = await AppWert.getInstance().signPrivateInvestment(investmentParams, phaseId);
		this.props.showModal(
			<WertModal widgetType={EWidgetType.MINT} signature={signature} appCollection={appCollection} onClose={this.props.hideModal} />,
		);
		this.closeModal();
	}

	private async reinvestByCard() {
		if (!this.state.modalProps) return;
		if (!this.state.modalProps.nft) return;
		const { appCollection, nft, amountToInvest, appTokenSupport, phaseId } = this.state.modalProps;
		const collectionAddress = appCollection.appContract?.address;
		if (!collectionAddress) throw new Error("No collection address");

		const investmentParams: ReinvestmentWertParams = {
			tokenId: nft.tokenId,
			contractAddress: collectionAddress,
			amountToInvest: amountToInvest.toString(),
			tokenSymbol: appTokenSupport.symbol,
		};

		const signature = await AppWert.getInstance().signPrivateReinvestment(investmentParams, phaseId);
		this.props.showModal(
			<WertModal widgetType={EWidgetType.MINT} signature={signature} appCollection={appCollection} onClose={this.props.hideModal} />,
		);
		this.closeModal();
	}

	private async completeMint() {
		if (this.state.paymentMethod === EPaymentMethod.CREDIT_CARD && this.state.modalProps?.nft) return await this.reinvestByCard();
		if (this.state.paymentMethod === EPaymentMethod.CREDIT_CARD) return await this.mintNftByCard();
		if (!this.state.modalProps) return;
		const { onCompleteMint } = this.state.modalProps;

		this.setState((prevState) => {
			return { ...prevState, isMintButtonClicked: true };
		});
		try {
			await TransactionToast.processTransaction(async () => await WalletStore.getInstance().sendTransaction(this.state.tx));
			onCompleteMint?.();
			this.setState((prevState) => {
				return { ...prevState, isMintTxExecuted: true };
			});
		} catch (e) {
			this.setState((prevState) => {
				return { ...prevState, isMintButtonClicked: false };
			});
			console.error(e);
		}
	}

	private openAddFundsModal() {
		const amountToPay = this.state.modalProps?.amountToInvest;
		const token = this.state.modalProps?.appTokenSupport;
		assert(amountToPay && token, "Token or AmountToPay is undefined in complete mint modal");
		this.props.showModal(<AddFunds amountToPay={amountToPay} token={token} onClose={this.props.hideModal} />);
	}

	private onCheckboxChange = (checked: boolean) => {
		this.setState({ isConditionPoliciesChecked: checked });
	};

	private getAddFundsMessage(): JSX.Element {
		return <I18n map={"modals.complete_mint.info_1"} vars={{ tokenName: this.state.modalProps?.appTokenSupport.name ?? "MATIC" }} />;
	}
}

export default function CompleteInvestModal() {
	const { showModal, hideModal } = useModal();

	return <CompleteInvestModalClass showModal={showModal} hideModal={hideModal} />;
}
