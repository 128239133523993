import EventService from "Services/EventEmitter";
import I18nEn from "Configs/I18n/en.json";

import ConfigStore from "./ConfigStore";
import LocalStorageService from "Services/LocalStorageService";
import LanguageHelper from "common/helpers/language";

type IVars = { [key: string]: string };

export type IKeyOfLng = keyof typeof I18nEn;

export type ILngType = {
	[key in string]: string | ILngType;
};

export enum SupportedLanguage {
	EN = "en",
	FR = "fr",
}

export default class I18nStore {
	private static instance: I18nStore;
	private readonly event = new EventService();
	private static readonly defaultLng = ConfigStore.getInstance().config.languages.default;
	private cache = new Map<string, any>();

	private static readonly internaLng = "internaLng";
	private lngs: { [key: string]: ILngType } = {
		initLng: I18nEn,
	};
	private _initAsset: ILngType = this.lngs[I18nStore.internaLng]!;
	private _asset: ILngType = this.lngs[I18nStore.internaLng]!;

	private constructor() {
		I18nStore.instance = this;
	}

	public static getInstance() {
		if (!I18nStore.instance) return new this();
		return I18nStore.instance;
	}

	public get initAsset() {
		return this._initAsset;
	}

	public get asset() {
		return this._asset;
	}

	public get lang(): (typeof ConfigStore.prototype.config.languages.available)[number] {
		return LocalStorageService.getInstance().items.lang.get() ?? I18nStore.defaultLng;
	}

	/**
	 * @returns removelistener callback
	 */
	public onChange(callback: (asset: ILngType) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	public async toggleTo(key: string) {
		const newKey = this.isLanguageAvailable(key) ? key : I18nStore.defaultLng;

		if (this.isLanguageLoaded(newKey)) {
			this.switch(this.lngs[newKey]!, newKey);
			return;
		}

		this.storeLang(newKey);
		const isNewKeyLoaded = await this.load(newKey);
		if (isNewKeyLoaded) {
			this.switch(this.lngs[newKey]!, newKey);
			return;
		}
	}

	public getTranslations(map: string | string[], vars?: IVars): string[] {
		const translations = [];

		if (typeof map === "string") {
			translations.push(this.translate(map, vars));
			return translations;
		}

		translations.push(
			...map.map((key) => {
				return this.translate(key, vars);
			}),
		);

		return translations;
	}

	public translate(key: string, vars: IVars = {}, lng?: string) {
		const cacheKey = key.concat(JSON.stringify(vars));

		const value = this.getCache(cacheKey);

		const asset = lng ? this.lngs[lng] || this.asset : this.asset;

		if (asset === this.asset && value !== undefined) return value;

		const assetValue: string | null = this.getFromObjectByKeyString(key, asset) ?? this.getFromObjectByKeyString(key, this.initAsset);

		if (!assetValue) return key;

		const resultWithVariables = Object.keys(vars).reduce((str, key) => {
			const regex = new RegExp("\\{\\{" + key + "\\}\\}", "gmi");
			return str.replace(regex, vars[key] ?? "");
		}, assetValue);

		if (asset === this.asset) {
			this.setCache(cacheKey, resultWithVariables);
		}

		return resultWithVariables;
	}

	private switch(asset: ILngType, key: string) {
		if (asset === this._asset) return;
		this._asset = asset;

		// whenever the application's language changes the localization cache is fully cleared:
		this.cache.clear();

		this.event.emit("change", { lang: key, ...this._asset });
	}

	public getFromObjectByKeyString(key: string, asset: { [key: string]: any }) {
		const keys = key.split(".");

		return keys.reduce((asset, key) => asset?.[key] ?? null, asset) as any;
	}

	private getCache(key: string): string | undefined {
		return this.cache.get(key);
	}

	private setCache(key: string, value: string) {
		this.cache.set(key, value);
	}

	public async autoDetectAndSetLanguage() {
		let key: string =
			LocalStorageService.getInstance().items.lang.get() ?? window.navigator.language.split("-")[0] ?? I18nStore.defaultLng;
		await this.toggleTo(key);
	}

	private storeLang(key: string) {
		const lng = LocalStorageService.getInstance().items.lang.get();

		if (!lng || lng !== key) {
			const supportedLang = LanguageHelper.getSupportedLanguage(key);
			LocalStorageService.getInstance().items.lang.set(supportedLang);
		}
	}

	private async load(key: string): Promise<boolean> {
		try {
			this.lngs[key] = I18nEn;
			return true;
		} catch (e) {
			console.error(e);
			return false;
		}
	}

	private isLanguageAvailable(key: string) {
		return ConfigStore.getInstance().config.languages.available.includes(key);
	}

	private isLanguageLoaded(key: string) {
		return Object.keys(this.lngs).includes(key);
	}
}
